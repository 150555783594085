// Import React
import React from 'react';

// Import Data
import { PrudenteOffice, VenceslauOffice, SaoJoseOffice } from '../../data/contact';

// Import CSS
import './Contact.css'

// Import Components
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import OfficeData from '../../components/OfficeData/OfficeData';
import ContactForm from '../../components/Form/Form';

function Contact() {
    return (
        <section id="contact">
            <div className="content padding responsive">
                <SectionTitle
                    arrows="right"
                    title="Contato"
                    subtitle="Nosso time espera por você"
                    reverse={true}
                />

                <div className="data-and-form">
                    <div>
                        <OfficeData
                            title={'Unidade de Presidente Venceslau'}
                            details={VenceslauOffice}
                        />

                        <OfficeData
                            title={'Unidade de Presidente Prudente'}
                            details={PrudenteOffice}
                        />

                        <OfficeData
                            title={'Unidade de São José do Rio Preto'}
                            details={SaoJoseOffice}
                        />
                    </div>

                    <ContactForm />
                </div>
            </div>
        </section>
    );
}

export default Contact;